<template>
  <div>
    <HomeSwiper
      @search="search($event)"
      placeholder-text="搜索企业"
      :show-search="true"
      src="/images/banner.jpg"
    ></HomeSwiper>

    <!--    <div class="search-box">-->
    <!--      <input type="text" placeholder="搜索企业" v-model="query.keyword" />-->
    <!--      <div class="search-btn" @click="handleClick()">-->
    <!--        <img src="/images/search.png" />-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="box-card">
      <el-card>
        <div slot="header" class="header">企业展示</div>
        <AreaNav :searchId="query.areaId"></AreaNav>
        <el-row :gutter="20">
          <el-col
            :span="6"
            class="box-card"
            v-for="item in tableData"
            :key="item.id"
            :xs="24"
            :sm="8"
            :md="6"
            :lg="6"
            :xl="6"
          >
            <div class="good-box" @click="handleClickItem(item.id)">
              <div class="image-box">
                <img v-if="item.coverUrl" :src="item.coverUrl" class="image" />
                <div v-else class="txt">{{ item.companyName }}</div>
              </div>
              <div class="bottom">
                <div class="name">{{ item.companyName }}</div>
                <div class="tip">
                  联系人：{{ item.linkMan }} 联系电话:{{ item.linkTel }}
                </div>
                <div class="address">
                  {{ item.areaName }} {{ item.address }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <div class="page">
          <el-pagination
            @size-change="handleSearch"
            @current-change="handleSearch"
            :current-page.sync="query.pageIndex"
            :page-size.sync="query.pageSize"
            :pager-count="5"
            layout="total, prev, pager, next"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import HomeSwiper from "@/components/Cms/HomeSwiper";
import { searchCompany } from "@/api/cms/company.js";
import AreaNav from "./components/AreaNav";

export default {
  components: {
    HomeSwiper,
    AreaNav,
  },
  data() {
    return {
      query: {
        pageIndex: 1,
        pageSize: 16,
        keyword: "",
        areaId: 0,
      },
      total: 0,
      tableData: [],
    };
  },
  methods: {
    handleClickItem(id) {
      window.open(`/cms/company/show/${id}`);
    },
    search(searchText) {
      this.query.keyword = searchText;
      this.searchCompany();
    },
    searchArea(id) {
      this.query.areaId = id;
      this.searchCompany();
    },
    handleSearch() {
      this.searchCompany();
    },
    searchCompany() {
      searchCompany(this.query).then((res) => {
        this.tableData = res.datas;
        this.total = res.totalRecords;
      });
    },
  },
  created() {
    if (this.$route.query.keyword) {
      this.query.keyword = this.$route.query.keyword;
    }
    if (this.$route.query.areaId) {
      this.query.areaId = Number(this.$route.query.areaId);
    }
    this.searchCompany();
  },
};
</script>

<style scoped lang="scss">
.box-card {
  padding: 20px;
  max-width: 1280px;
  margin: auto;
  .header {
    font-size: 20px;
    font-weight: 700;
  }

  .page {
    text-align: center;
  }
}

.good-box {
  cursor: pointer;
  border: 1px solid #ededed;
  padding: 10px;

  .image-box {
    width: 100%;
    display: block;
    height: 200px;
    background-color: #fafafa;
  }

  .txt {
    padding: 10px;
    font-size: 26px;
    font-weight: 300;
  }

  .image {
    width: 100%;
    height: 100%;
  }

  .bottom {
    margin-top: 10px;
    color: #3d3d3d;

    .name {
      margin-top: 15px;
      font-size: 15px;
    }

    .tip,
    .address {
      margin-top: 8px;
      font-size: 12px;
      height: 32px;
      overflow: hidden;
    }
  }
}

.good-box:hover {
  border-color: #f40;
}

@media screen and (max-width: 700px) {
  .box-card {
    padding: 8px;
  }
}
</style>
